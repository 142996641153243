const nextButtons = document.querySelectorAll('.form-step .main-button')
const steps = document.querySelectorAll('.form-step')
const bullets = document.querySelectorAll('.bullet')
const form = document.querySelector('form')

const formData = {
	currentStep: 1,
	type: '',
	housetype: '',
}

const setVerbrauch = verbrauch => {
	form.verbrauch.value = verbrauch
}

 

const initForm = () => {
	
	bullets.forEach(b => b.classList.remove('active'))
	
	steps.forEach(step => {
		
		// Darstellung Step und Bullet
		step.classList.remove('active')
		if (formData.currentStep === parseInt(step.dataset.question)) {
			step.classList.add('active')
			bullets[formData.currentStep - 1].classList.add('active')
		}
		if (formData.currentStep < steps.length && formData.currentStep >= parseInt(step.dataset.question)) {
			bullets[formData.currentStep - 1].classList.add('activated')
		}
	})

	if (formData.type === 'Beratungsgespräch') {
		form.email.required = false
		form.email.placeholder = "E-Mail Adresse"
		form.phone.required = true
		form.phone.placeholder = "Telefonnummer*"
	}
	if (formData.type === 'Informationen') {
		form.email.required = true
		form.email.placeholder = "E-Mail Adresse*"
		form.phone.required = false
		form.phone.placeholder = "Telefonnummer"
	}

	console.log(formData);
}


nextButtons.forEach(btn => {
	btn.addEventListener('click', () => {
		formData.currentStep++
		if (btn.dataset.step == 1) {
			formData.type = form.q1.value
			form.dataset.type = form.q1.value
		}
		if (btn.dataset.step == 2) {
			formData.housetype = form.q2.value
			if (formData.housetype === 'Industrie') {
				formData.currentStep++
				bullets[2].classList.remove('activated')
			}
			form.dataset.housetype = form.q2.value
		}
		if (formData.currentStep > steps.length) {
			formData.currentStep = steps.length
		}
		initForm()
	})

})


const enableButton = (step) => {
	const btn = document.querySelector(`[data-step="${step}"]`)
	btn.classList.remove('disabled')
}

const openStep = step => {
	formData.currentStep = step
	initForm()
}



initForm()

form.addEventListener('submit', validateForm)

const validateForm = (e) => {
	e.preventDefault();

	const checkTel = /^[0-9()+/– -]+$/;
    const checkMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	const requiredFields = document.querySelectorAll('input[required]')
	let valid = true

	//Reset Errors
	const errorMessages = document.querySelectorAll(`[data-error]`)
	errorMessages.forEach(m => m.innerHTML = "")

	// Prüfe ob Pflichtfelder ausgeffüllt sind
	requiredFields.forEach(field => {

		if (field.value === '') {
			valid = false
			document.querySelector(`[data-error="${field.id}"]`).innerHTML = '<span>Bitte ausfüllen</span>'
		}
		if (!form.consent.checked) {
			valid = false
			document.querySelector(`[data-error="consent"]`).innerHTML = '<span>Bitte stimmen Sie den Datenschutzbestimmungen zu</span>'
		}

	})

	//Prüfe auf gültige E-Mail
	if (checkMail.test(form.email.value) === false) {
		document.querySelector(`[data-error="email"]`).innerHTML = '<span>Bitte gültige E-Mail Adresse angeben!</span>'
	}

	if (valid) {
		form.submit()
	} else {
		console.log('Missing');
	}
}